import React from "react";
import Testimonial from "../side-components-home/Testimonial";

function Testimonials() {
  return (
    <div>
      <Testimonial />
    </div>
  );
}

export default Testimonials;
